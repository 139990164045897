<template>
  <div class="m-maps">
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2787.8054414578!2d25.608919712368696!3d45.67480597095758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b35bf77fe7ea01%3A0xea0480e99def5dbb!2sMr%20Jeff%20-%20Curatatorie%20Brasov!5e0!3m2!1sro!2sro!4v1722629090958!5m2!1sro!2sro"
      width="100%"
      height="600"
      style="border:0;"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
    />
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.m-maps {
  border-radius: 10px;
  overflow: hidden;
  filter: drop-shadow(1px 1px 4px rgba(31, 54, 90, 0.2));
}
</style>
