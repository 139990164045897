<template>
  <div
    class="a-section"
    :class="{ 'a-section__spacer': spacer }"
    :style="`background-color: ${background || '#edf8fd'}`"
  >
    <slot name="title">
      <template v-if="title || subtitle">
        <h3 v-if="subtitle" class="subtitle">{{ $t(subtitle) }}</h3>
        <h2 v-if="title" class="title">{{ $t(title) }}</h2>
      </template>
    </slot>
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    background: {
      type: String,
      default: "",
    },
    spacer: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.a-section {
  padding: var(--spacer-2xl) 0;
  @include for-mobile {
    padding: var(--spacer-lg) 0;
  }

  &__spacer {
    margin: var(--spacer-2xl) 0;
  }
  .subtitle {
    text-transform: uppercase;
    color: var(--c-primary-darken);
    font-family: var(--font-family-primary);
    font-size: var(--font-base);
    font-weight: var(--font-bold);
    margin: 0;
    text-align: center;
  }
  .title {
    font-family: var(--font-family-secondary);
    font-size: 2.5rem;
    font-weight: var(--font-bold);
    margin: var(--spacer-sm) 0;
    text-align: center;
    @include for-mobile {
      font-size: 1.75rem;
      margin: 0 auto;
      padding: 0 var(--spacer-sm);
    }
  }
}
</style>
