<template>
  <div class="contact">
    <mPageTitle
      :title="$t(page.title)"
      :description="$t(page.description)"
      :image="require('@/assets/images/webp/contact.webp')"
    />
    <aSection :background="'#fff'">
      <div class="container contact__container">
        <mMaps class="contact__container--section" />
        <div class="contact__container--section">
          <div class="d-flex">
            <div class="contact__container--section-item">
              <h3 class="contact__container--title">{{ $t("Program") }}</h3>
              <div class="contact__container--description">
                <div class="contact__container--description-icon">
                  <span>{{ $t("schedule_monday") }}</span>
                </div>
                &ndash;
                <div class="contact__container--description-icon">
                  <span>{{ $t("schedule_friday") }}</span>
                </div>
                08:00 - 20:00
              </div>
              <div class="contact__container--description">
                <div class="contact__container--description-icon">
                  <span>{{ $t("schedule_saturday") }}</span>
                </div>
                09:00 - 15:00
              </div>
              <div class="contact__container--description">
                <div class="contact__container--description-icon">
                  <span>{{ $t("schedule_sunday") }}</span>
                </div>
                {{ $t("Închis") }}
              </div>
            </div>
            <div class="contact__container--section-item">
              <h3 class="contact__container--title">{{ $t("Contact") }}</h3>
              <div class="contact__container--description">
                <div class="contact__container--description-icon">
                  <SfIcon :icon="'phone'" />
                </div>
                <a href="tel:40756724674">0756 724 674</a>
              </div>
              <div class="contact__container--description">
                <div class="contact__container--description-icon">
                  <SfIcon :icon="'marker'" />
                </div>
                <a
                  href="https://maps.app.goo.gl/KwsoHoibfXgQZg7a6"
                  target="_blank"
                >
                  {{ $t("Strada Ioan Popasu, Nr. 2, Brașov") }}
                </a>
              </div>
              <div class="contact__container--description">
                <div class="contact__container--description-icon">
                  <SfIcon :icon="'mail'" />
                </div>
                <a href="mailto:office@luxbrumalis.ro">office@luxbrumalis.ro</a>
              </div>
            </div>
          </div>
          <div class="contact__container--section-item">
            <h3 class="contact__container--title">{{ $t("Social Media") }}</h3>
            <div class="contact__container--social-wrapper">
              <div
                v-for="(social, i) in socialMedia"
                :key="`social-${i}`"
                @click="openExternal(`https://${social.link}`)"
                class="contact__container--social"
              >
                <div class="contact__container--social-icon">
                  <SfIcon :icon="social.icon" />
                </div>
                <div class="contact__container--social-description">
                  <h4>{{ $t(social.title) }}</h4>
                  <p>{{ $t(social.description) }}</p>
                  <a href="#">{{ social.link }}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </aSection>
  </div>
</template>
<script>
import mPageTitle from "../components/molecules/m-page-title.vue";
import mMaps from "../components/molecules/m-maps.vue";
import aSection from "../components/atoms/a-section.vue";
import { SfIcon } from "@storefront-ui/vue";

export default {
  metaInfo() {
    return {
      title: this.$t("Contact"),
    };
  },
  components: {
    mPageTitle,
    mMaps,
    aSection,
    SfIcon,
  },
  data() {
    return {
      page: {
        title: "Contact",
        description:
          "La Mr Jeff, suntem mereu aici pentru tine! Dacă ai întrebări, sugestii sau ai nevoie de asistență, nu ezita să ne contactezi",
      },
      socialMedia: [
        {
          icon: "facebook",
          title: "Facebook",
          description: "Urmărește-ne pe Facebook",
          link: "facebook.com/jeffbrasov",
        },
        {
          icon: "instagram",
          title: "Instagram",
          description: "Urmărește-ne pe Instagram",
          link: "instagram.com/mrjeff.brasov",
        },
        {
          icon: "whatsapp",
          title: "WhatsApp",
          description: "Scrie-ne pe WhatsApp",
          link: "wa.me/40756724674",
        },
      ],
    };
  },
  mounted() {
    window.scrollTo({ top: 0 });
  },
  methods: {
    openExternal(link) {
      window.open(link, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.contact {
  &__container {
    display: flex;
    @include for-desktop {
      align-items: center;
      gap: var(--spacer-2xl);
      padding: var(--spacer-sm) 0;
    }
    @include for-mobile {
      padding: var(--spacer-sm);
      width: calc(100% - 2rem);
      flex-direction: column-reverse;
    }
    &--section {
      &:first-child {
        flex: 1 1 45%;
      }
      &:last-child {
        flex: 1 1 55%;
      }
      flex: 1 1 50%;
      &-item {
        margin: var(--spacer-xl) 0;
        @include for-mobile {
          margin: var(--spacer-lg) 0;
          flex: 1 1 50%;
        }
      }
    }
    &--title {
      font-size: 1.75rem;
      font-weight: var(--font-semibold);
      margin: 0 0 var(--spacer-base) 0;
    }
    &--description {
      font-size: var(--font-sm);
      font-weight: var(--font-medium);
      line-height: 1.4;
      margin: 0 0 var(--spacer-sm) 0;
      display: flex;
      align-items: center;
      color: var(--c-dark);
      @include for-mobile {
        height: 2.25rem;
      }
      &-icon {
        --icon-size: 1.25rem;
        --icon-color: var(--c-white);
        padding: var(--spacer-2xs);
        border-radius: 6px;
        margin: 0 var(--spacer-2xs);
        background-color: var(--c-primary-darken);
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: var(--spacer-xs);
        }
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.25rem;
          height: 1.25rem;
          font-weight: var(--font-bold);
          color: var(--c-white);
        }
      }
      a {
        color: var(--c-dark);
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &--social {
      padding: var(--spacer-sm);
      margin: var(--spacer-xs);
      border: 1px solid var(--c-primary-darken);
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 10rem;
      min-width: 10rem;
      transition: 0.3s ease;
      cursor: pointer;
      @include for-mobile {
        width: 100%;
        margin: var(--spacer-xs) 0;
        min-height: 8rem;
      }
      &:hover {
        background: var(--c-light);
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
      &-icon {
        --icon-size: 1.25rem;
        --icon-color: var(--c-white);
        width: fit-content;
        padding: var(--spacer-2xs);
        border-radius: 6px;
        margin: 0 var(--spacer-2xs);
        background-color: var(--c-primary-darken);
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: var(--spacer-xs);
        }
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 1.25rem;
          height: 1.25rem;
          font-weight: var(--font-bold);
          color: var(--c-white);
        }
      }
      &-description {
        color: var(--c-dark);
        h4 {
          margin: 0;
          font-weight: var(--font-medium);
          font-size: var(--font-base);
        }
        p {
          margin: var(--spacer-xs) 0;
          font-size: var(--font-sm);
        }
        a {
          display: block;
          font-size: var(--font-xs);
          font-weight: var(--font-bold);
          color: var(--c-dark);
          margin: var(--spacer-sm) 0 0 0;
        }
      }
    }
  }
}
.d-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
